import React, { useRef, useState } from "react";
import image from "../assets/images/saluto_bg.png";
import useTranslation from "../hooks/useTranslation";
import { init } from "@emailjs/browser";
import emailjs from "@emailjs/browser";
import { Pocket } from "../commons/Icons/Icons";
import Header from "@components/Header";
import {
  Link,
  Button,
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
  scroller,
} from "react-scroll";

interface ViewProps {
  pathName: string;
}

interface FormInput {
  fromName: string;
  email: string;
  message: string;
}

function ContactView(props: ViewProps) {
  const { t } = useTranslation(props.pathName);
  const [typeOfPerson, setTypeOfPerson] = useState<"privat" | "employer">(
    "privat"
  );
  const form = useRef<HTMLFormElement | null>(null);
  init("qsoSQtlM6Axqe-U2Z");

  const sendEmail = async (event: any) => {
    event.preventDefault();
    if (!form.current) {
      console.error("Something must have gone wrong...");
      return false;
    }

    const validateInput = (input: FormInput) => {
      const errors: string[] = [];

      if (!input.fromName) errors.push("Provide a name!");
      if (!input.email) errors.push("Provide an email!");

      if (errors.length === 0) return null;
      return errors;
    };

    const values = {
      email: form.current.email.value,
      fromName: form.current.fromName.value,
      typeOfPerson: typeOfPerson,
      message: form.current.message.value,
    };
    console.log(values);
    const errors = validateInput(values);

    if (errors !== null) {
      alert(errors.join("\n"));
      return;
    }

    try {
      await emailjs.send("service_k88bifl", "template_qrwt2n9", values);

      alert(
        t(
          "Tack för ditt meddelande! Vi svarar till din mejladress ({{email}}) så snart vi kan. 🎈",
          { email: values.email }
        )
      );
    } catch (error) {
      console.error(error);
    }
  };

  const CheckBoxes = () => {
    const MyBox = (props: {
      title: string;
      selected: boolean;
      onClick: () => void;
    }) => {
      return (
        <div
          onClick={props.onClick}
          className="flex items-center gap-x-2 mr-2 sm:my-0"
        >
          <div
            style={{ borderColor: "#464C63" }}
            className="p-1 border-2 rounded-2xl"
          >
            <div
              style={{
                borderColor: "#464C63",
                backgroundColor: props.selected ? "#464C63" : "transparent",
              }}
              className="h-7 w-7 border-2 rounded-xl"
            ></div>
          </div>
          <p> {props.title}</p>
        </div>
      );
    };

    return (
      <>
        <div className="flex flex-wrap gap-y-2 ">
          <div className="sm:flex justify-between sm:m-auto w-full flex-col sm:flex-row ">
            <div className="sm:mb-0 mb-2">
              <MyBox
                onClick={() => setTypeOfPerson("privat")}
                selected={typeOfPerson === "privat"}
                title={t("Private citizen")}
              ></MyBox>
            </div>
          </div>

          <MyBox
            onClick={() => {
              setTypeOfPerson("employer");
            }}
            selected={typeOfPerson === "employer"}
            title={t("Employer")}
          ></MyBox>
        </div>
      </>
    );
  };

  return (
    <>
      <Element name="contact">
        <div
          style={{
            height: "75vh",
            backgroundPosition: "fit",
            backgroundRepeat: "no-repeat",
            backgroundSize: "cover",
          }}
        >
          <div className="sm:w-5/6 m-auto  flex sm:flex-nowrap flex-wrap ">
            <div className="sm:w-1/2 h-3/5 mt-auto pt-10 sm:order-1 order-2 sm:visible invisible">
              <Pocket></Pocket>
            </div>
            <div className=" sm:w-1/2 w-full px-10 flex-col sm:mt-auto sm:mb-auto sm:order-2 order-1 mt-5">
              <div className="mb-5">
                <Header text={t("CONTACT US")}></Header>
              </div>

              <form className="w-full" ref={form} onSubmit={sendEmail}>
                <div className="mb-4">
                  <div className="flex my-5">
                    <CheckBoxes></CheckBoxes>
                  </div>
                  <label className=" text-gray-700 text-sm font-bold mb-2">
                    {t("Name")}
                  </label>
                  <input
                    className="bg-transparent border rounded w-full py-2 px-3 text-gray-700 leading-6 focus:outline-none focus:shadow-outline "
                    id="fromName"
                    type="text"
                    placeholder={t("Name")}
                  />
                </div>
                <div className="mb-4">
                  <label className=" text-gray-700 text-sm font-bold mb-2">
                    {t("Email")}
                  </label>
                  <input
                    className=" border rounded w-full py-2 px-3 text-gray-700 leading-6 focus:outline-none focus:shadow-outline "
                    id="email"
                    type="text"
                    placeholder={t("Email")}
                  />
                </div>
                <div className="mb-4">
                  <label className=" text-gray-700 text-sm font-bold mb-2">
                    {t("Message")}
                  </label>
                  <textarea
                    id="message"
                    placeholder={t("Message")}
                    className="word-wrap: break-word border rounded w-full py-2 px-3 text-gray-700 leading-6 focus:outline-none focus:shadow-outline h-16"
                  ></textarea>
                </div>

                <button
                  style={{ color: "whitesmoke", background: "#697291" }}
                  className="p-5 w-full flex items-stretch  rounded-md justify-center"
                  formAction="submit"
                  name="submit-contact-form-button"
                >
                  {t("Send")}
                </button>
              </form>
            </div>
          </div>
        </div>
      </Element>
    </>
  );
}

export default ContactView;
