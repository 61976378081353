import { useEffect } from "react";
import * as React from "react";

import Topbar from "../components/Topbar";
import AboutView from "../views/AboutView";
import BehaviourView from "../views/BehaviourView";
import FirstView from "../views/FirstView";
import FootView from "../views/FootView";
import HowView from "../views/HowView";
import StepView from "../views/StepView";
import useTranslation from "../hooks/useTranslation";
import ContactView from "../views/ContactView";
import { Helmet } from "react-helmet";

const AboutPage = (props: any) => {
  useEffect(() => {
    window.location.href = "https://www.saluto.ai";
  }, []);

  return <div className=" w-screen overflow-hidden"></div>;

  return (
    <>
      <Helmet>
        <title>{"Saluto"}</title>
      </Helmet>
      <Topbar pathName={props.location.pathname}></Topbar>
      <AboutView pathName={props.location.pathname}></AboutView>
      <ContactView pathName={props.location.pathname}></ContactView>
      <FootView pathName={props.location.pathname}></FootView>
    </>
  );
};

export default AboutPage;
